<script>
import { nextTick } from 'vue'
import { mixin as clickaway } from 'vue-clickaway'
import ui from '/~/core/ui'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BasePlaceholder from '/~/components/base/placeholder/base-placeholder.vue'
import { useNotifications } from '/~/composables/notifications'
import AppNotificationItem from './app-notification-item.vue'

export default {
  name: 'app-notifications',
  components: {
    BasePlaceholder,
    BaseAction,
    BaseButton,
    BaseIcon,
    AppNotificationItem,
  },
  mixins: [clickaway],
  props: {
    icon: {
      type: String,
      default: 'notifications',
    },
  },
  setup() {
    const { notificationsProcessor, hasUnreadNotifications } =
      useNotifications()

    return {
      ...clickaway.setup?.(...arguments),
      notificationsProcessor,
      hasUnreadNotifications,
      ui,
    }
  },
  data() {
    return {
      opened: false,
    }
  },
  computed: {
    unreadCount() {
      return this.notificationsProcessor.hits.filter((i) => !i.isRead).length
    },
    showViewMore() {
      return (
        this.notificationsProcessor.length &&
        this.notificationsProcessor.perPage < this.notificationsProcessor.total
      )
    },
  },
  watch: {
    opened(value) {
      if (value) {
        nextTick(() => {
          this.$refs.clearAllButton?.$el.focus()
        })
      }
    },
  },
  methods: {
    close() {
      this.opened = false
    },
    markAllAsRead() {
      this.notificationsProcessor.hits.forEach((notification) => {
        notification.markAsRead()
      })
    },
    clearAll() {
      this.notificationsProcessor.clearAll()
    },
  },
}
</script>

<template>
  <div v-on-clickaway="close">
    <div
      class="relative flex h-10 w-10 items-center justify-center rounded-full text-eonx-neutral-800 transition duration-200 hover:text-eonx-neutral-600 md:h-12 md:w-12"
    >
      <base-action
        class="flex items-center justify-center rounded-full p-1.5"
        type="action"
        @click="opened = !opened"
      >
        <base-icon
          :svg="icon"
          :class="opened && 'text-primary'"
          :size="ui.mobile ? 26 : 32"
        />
        <span
          v-if="hasUnreadNotifications"
          data-test="badge"
          class="absolute top-0 right-0 box-content h-2.5 w-2.5 rounded-full border border-error bg-fg-error"
        />
      </base-action>
    </div>
    <transition name="slide-y">
      <div
        v-if="opened"
        :style="!ui.mobile && { maxHeight: '80vh' }"
        class="fixed top-0 right-0 z-modal flex h-screen w-screen flex-col bg-default text-base text-default sm:absolute sm:top-auto sm:h-auto sm:min-h-32 sm:w-xse sm:rounded-md sm:shadow-lg"
        @keyup.esc="close"
      >
        <header class="flex h-14 shrink-0 items-center border-b px-5 sm:h-12">
          <span
            class="text-sm font-bold uppercase text-eonx-neutral-600 md:text-base"
          >
            {{ `Notifications (${unreadCount})` }}
          </span>
          <div class="ml-auto flex items-center space-x-2.5 sm:space-x-5">
            <base-button
              ref="clearAllButton"
              look="link"
              :disabled="
                notificationsProcessor.isEmpty ||
                notificationsProcessor.isLoading
              "
              @click="clearAll"
            >
              <span
                class="text-sm font-bold sm:text-base"
                :class="{
                  'text-eonx-neutral-600': notificationsProcessor.isEmpty,
                  'text-primary-darken': !notificationsProcessor.isEmpty,
                }"
              >
                Clear All
              </span>
            </base-button>
            <base-button
              look="link"
              :disabled="!hasUnreadNotifications"
              @click="markAllAsRead"
            >
              <span
                class="text-sm font-bold sm:text-base"
                :class="[
                  hasUnreadNotifications
                    ? 'text-primary-darken'
                    : 'text-eonx-neutral-600',
                ]"
              >
                Mark all as read
              </span>
            </base-button>
            <base-button
              icon="plain/close"
              class="ml-2.5 block sm:hidden"
              @click="close"
            />
          </div>
        </header>
        <div class="overflow-y-auto">
          <div v-if="notificationsProcessor.isLoading" class="p-5">
            <base-placeholder type="text" />
          </div>
          <div
            v-else-if="notificationsProcessor.isEmpty"
            class="flex items-center justify-center p-[30px]"
          >
            No notifications
          </div>
          <template v-else>
            <app-notification-item
              v-for="item in notificationsProcessor.hits"
              :key="item.id"
              :item="item"
              class="hover:bg-dark"
              @close="opened = false"
            />
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>
